'use strict';

Gri.module({
  name: 'step-alpha',
  ieVersion: null,
  $el: $('.step-alpha'),
  container: '.step-alpha',
  fn: function () {
    $(document).ready(function () {
      // Variables
      var $number = $('.step-alpha .number');
      var $body = $("body");
      var numberpositioncheck = $("header").height();
      var numberpositioncheck2 = $body.height() - $("footer-alpha").height();
      var stepcount = $(".step-alpha .step").length;
      var maxheight = 0;


      // Functions
      function numbercheck() {
        if($("html").scrollTop() > numberpositioncheck && $("html").scrollTop() < numberpositioncheck2){
          $number.css({
            "top" : "50%",
            "position": "fixed"
          });
        }else{
          $number.css({
            "position": "absolute",
            "top" : "50px"
          })
        }
      }


      function numbertext() {
        for(var i=1; i <= stepcount; i++){
          if($("html").scrollTop()< 420){
            $number.text("1");
          }else if($("html").scrollTop() > maxheight*(i-1) + numberpositioncheck && $("html").scrollTop() < (maxheight * i) + numberpositioncheck2){
            $number.text(i);
          }
        }
      }

      function heightupdate() {
        $(".step-alpha .step").each(function() {
          if(maxheight < $(this).height()){
            maxheight = $(this).height();
          }
        });
        $(".step-alpha .step").height(maxheight);
      }


      //RUN


      if ($(window).width() > 960){
        heightupdate();
      }
      $(window).scroll(function(){
        numbercheck();
        numbertext();
      });


    })
  }
});
